import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../components/scss/app.scss"

const AboutKalotiPage = () => (
    <Layout>
    <SEO title="Teeth Whitening Services in Toronto | The Smile Stylist" 
    description="Looking for a whiter smile? With professional Cosmetic Teeth Whitening treatments, the Smile Stylist can help you achieve instant results." 
    keywords="Teeth Whitening Services" />
    <main className="site-content" role="main">
    <section id="main-top-img" className="teeth-whitening hidden-xs">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 sec-title wow animated fadeInDown">
            <h1>Teeth Whitening</h1>
            <h2>It is a big part of our practice</h2>
          </div>
        </div>
      </div>
    </section>

    <section id="main-white">
      <div className="container">
        <div className="row">
          <div className="col-sm-8 wow animated fadeInRight">
            <p><h2><a href="/teeth-whitening">Teeth Whitening</a></h2></p>
            <p className="lead">Cosmetic teeth whitening is a big part of our practice.</p>
            <p>We get great results for our clients and offer many options to fit the most discerning clients expectations and budgets.</p>
            <p><strong>Here are three general approaches to achieving a whiter smile:</strong></p>
            <ul>
              <li><strong>Whitening toothpastes</strong></li>
              <li>
                <strong>At-home bleaching</strong>
                <ul>
                  <li>Over-the-counter whitening strips</li>
                  <li>Over-the-counter whitening gels</li>
                  <li>Over-the-counter tray-based bleaching systems (purchased at your local drug store, over the internet, by mail)</li>
                  <li>Dentist supervised tray-based whitening system (whitening supplies are purchased through our office)</li>
                </ul>
              </li>
              <li><strong>In-office bleaching</strong> – also called chair side, laser or power bleaching</li>
            </ul>
            <h2>How do whitening toothpastes work and how effective are they at whitening teeth?</h2>
            <p>All toothpastes help remove surface stains through the action of mild abrasives. Some whitening toothpastes contain gentle polishing or chemical agents that provide additional stain removal effectiveness. Whitening toothpastes can help remove surface stains only and do not contain bleach; over-the-counter and professional whitening products contain hydrogen peroxide (a bleaching substance) that helps remove stains on the tooth surface as well as stains deep in the tooth. None of the home use whitening toothpastes can come even close to producing the bleaching effect you get from our practice. Whitening toothpastes can lighten your tooth’s colour by about one shade. In contrast, light-activated whitening conducted in our office can make your teeth three to eight shades lighter.</p>
            <h2>What about whitening strips and whitening gels? What’s the opinion on these products?</h2>
            <h3>Whitening gels</h3>
            <p>These clear, peroxide-based whitening gels are applied with a small brush directly to the surface of your teeth. Instructions generally call for twice a day application for 14 days. Initial results are seen in a few days and final results are sustained for about 4 months.</p>
            <h3>Whitening strips</h3>
            <p>These are very thin, virtually invisible strips that are coated with a peroxide-based whitening gel. The strips are applied twice daily for 30 minutes for 14 days. Initial results are seen in a few days and final results are sustained for about 4 months.</p>
            <p>Both of these products contain peroxide in a concentration that is much lower than the peroxide-based products that are used in our practice. Although some teeth lightening will be achieved, the degree of whitening is much lower than results achieved with in-office or dentist-supervised whitening systems. Additionally, use of over-the-counter products do not benefit from our close — to determine what whitening process may be best for you, to check on the progress of your teeth whitening process and look for signs of gum irritation. On the positive, the over-the-counter gels and strips are considerably less expense than the top-of-the line in-office whitening procedures.</p>
            <h2>Tray-based, tooth whitening procedure</h2>
            <p>For the more traditional types of teeth whitening procedures using tray-based tooth whitening systems purchased over-the-counter or through our practice, the first step involves filling a mouth guard-like tray with a gel-like whitening solution — which contains a peroxide-bleaching agent. This tray is then placed over the teeth and worn for a period of time, generally from a couple hours a day or every day during the overnight for up to 4 weeks and even longer (depending on the degree of staining and desired level of whitening).</p>
            <h2>In-office-based tooth whitening procedure</h2>
            <p>Bleaching procedures performed in the dentist’s office involve a few different or additional steps — namely, that a protective gel or guard is positioned over the gums before the bleaching agent is applied, the bleaching agent is applied directly to the teeth, and the whitening process is hastened with the use of heat, a special light, or laser light that is directed at the chemically coated teeth. There are other differences that are addressed in the next question.</p>
            <h2>What are the main differences between in-office bleaching procedures and the at-home and over-the-counter tray-based bleaching systems?</h2>
            <h3>Strength of bleaching agent</h3>
            <p>For starters, over-the-counter home use products and dentist-supervised at-home products usually contain a lower strength-bleaching agent (from 10% carbamide peroxide–which is equivalent to about 3 percent hydrogen peroxide — up to 22% carbamide peroxide). In-office, professionally applied tooth whitening products contain hydrogen peroxide in concentrations ranging from 15 to 43 percent.</p>
            <h3>Mouthpiece trays</h3>
            <p>With dentist-supervised at-home bleaching products, we will take an impression of your teeth and make a mouthpiece tray that is customized to exactly fit your teeth. This customization allows for maximum contact between the whitening gel, which is applied to the mouthpiece tray, and the teeth. A custom-made tray also minimizing the gel’s contact with gum tissue. Over-the-counter whitening products do contain a mouthpiece tray, but the “one-size-fits-all” approach means that the fit will not be exact. Ill-fitting trays can irritate the gum and soft tissue by allowing more bleaching gel to seep onto these tissues. With in-office procedures, the bleaching agent is applied directly to the teeth.</p>
            <h3>Additional protective measures</h3>
            <p>In the office setting, we will apply either a gel to the gum tissue or use a rubber shield (which slides over the teeth) prior to treatment to protect your gums and oral cavity from the effects of the bleaching. Over-the-counter products don’t provide these extra protective measures.</p>
            <h3>Speed of the bleaching process</h3>
            <p>A dentist-supervised At-home bleaching and over-the-counter bleaching products typically need to be applied every day for 1 or 2 hours or every overnight for up to 4 weeks. In-office bleaching provides the quickest and most effective way to whiten teeth. With in-office bleaching, the whitening product is applied directly to the teeth. These products can be used in combination with heat, a special light, and/or a laser. The light and/or heat accelerate the whitening process. Results are seen in only 1, 30- to 60-minute treatment. To achieve dramatic results, however, several appointments are usually needed. With laser-enhanced bleaching, however, dramatic results can be seen after the first treatment.</p>
            <h3>Costs</h3>
            <p>Over-the-counter bleaching systems are the least expensive option. Depending on your mouth and the extent of the procedure, dentist-supervised home bleaching systems range in cost from approximately $150 to $300 per upper or lower set of teeth, or $300 to $600 for the whole mouth. In-office bleaching procedure range in cost from $200 to $500 per arch, or $500 to $1,000 for the whole mouth.</p>
            <h3>Supervised versus unsupervised process</h3>
            <p>Dentist-supervised at-home bleaching and in-office treatment offer additional benefits compared with over-the-counter procedures. First, we can perform an oral examination and consider your complete medical history, which can be helpful in determining how your teeth became discoloured and if bleaching is an appropriate course of treatment based on your type of stains and number, type, and location of restorations. We can then better match the type of stain with the best treatment, if appropriate, to lighten those stains. With dentist-supervised bleaching procedures, we will likely see you a couple of times to ensure you are following directions, to make sure the customized tray is fitting properly, to inspect your gums for signs of irritation, and to generally check on how the teeth whitening process is proceeding. Contact us today to find out which whitening option is the right one for you.</p>
            <p><a className="btn btn-red" href="/contact">Book Your Appointment</a></p>
          </div>
          <div className="col-sm-4 wow animated zoomIn text-center service__shadow-imgs" data-wow-delay="0.4s">

            <p>
              <img src={ require("../images/desktop/services/Whitening.jpg" )} className="img-responsive" alt="Teeth Whitening Treatment" />
            </p>
            <p>
              <img src={ require("../images/desktop/services/free-teeth-whitening.jpg" )} className="img-responsive" alt="Free Teeth Whitening For New Patients " />
            </p>


          </div>
        </div>
      </div>
    </section>
  </main>
  </Layout>
)

export default AboutKalotiPage